<template>
  <div class="content-wrapper register-page">
    <div class="login-box">
      <div class="login-logo">
        <img :src="this.$client_picture_baseurl + 'logo.png'" width="100px" height="100px" alt="" class="brand-image img-circle elevation-3 mb-4" style="opacity: 1.0"><br/>
        <router-link to="/login"><b>{{ this.client.appname }}</b></router-link><skeleton-loader-vue v-show="skeleton" class="skeleton" type="rect" :rounded="true" :width="200" :height="51" animation="fade" />
      </div>
      <!-- /.login-logo -->
      <div class="card">
        <div class="card-body login-card-body">
          <p class="login-box-msg">Forgor password? Please enter your email</p>

          <form @submit.prevent="handleSubmit">
            <div class="input-group mb-3">
              <input v-model="email" type="email" class="form-control" placeholder="Email">
              <div class="input-group-append">
                <div class="input-group-text">
                  <span class="fa fa-envelope"></span>
                </div>
              </div>
            </div>
            
            <div class="row">
              <div class="col-8">
              </div>
              <!-- /.col -->
              <div class="col-12 mb-2">
                <button type="submit" :disabled="loading" class="btn btn-primary btn-block"><i v-show="loading" class="spinner-border spinner-border-sm"></i> Confirm</button>
              </div>
              <!-- /.col -->
            </div>
          </form>
          <p class="mb-1">
            <router-link to="/login">Proceed to login page</router-link>
          </p>
          <p class="mb-0">
            <router-link to="/register" class="text-center">Register new account</router-link>
          </p>
        </div>
        <!-- /.login-card-body -->
      </div>
      <small v-if="!skeleton" class="d-flex justify-content-center text-secondary mt-3">Version {{ this.client.version }}</small>
      <span v-else class="d-flex justify-content-center text-secondary mt-3"><skeleton-loader-vue type="rect" :rounded="true" :width="60" :height="12" animation="fade" /></span>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import VueSkeletonLoader from 'skeleton-loader-vue';

export default {
  name: 'Forgot',

  components: {
    VueSkeletonLoader,
  },

  data() {
    return {
      client: {},
      email: '',
      loading: false,
      skeleton: true,
    }
  },

  methods: {
    async handleSubmit() {
      this.loading = true;

      await axios.post('api/forgot', {
        email: this.email
      }).then((response) => {
        this.$swal.fire({
          icon: 'success',
          title: response.data.message,
        })
        this.loading = false;
      }).catch((err) => {
        this.$swal.fire({
          icon: 'error',
          title: err.response.data.message,
        })
        this.loading = false;
      });
    }
  },

  async mounted () {
    this.$Progress.start();
    await axios.get('api/client').then(({ data }) => (
      this.client = data,
      this.skeleton = false
    ));
    this.$Progress.finish();
  }

}
</script>

<style scoped>
.content-wrapper {
  margin-left: 0 !important;
  min-height: 0px !important;
}
.log-box {
  width: 360px;
  margin: 0 auto 0;
  padding-top: 10%;
}
.content-wrapper {
  margin-left: 0 !important;
}
.skeleton {
  width: 100% !important;
}
</style>
