<template>
  <!-- Content Wrapper. Contains page content -->
  <div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Settings</h1>
          </div><!-- /.col -->
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">Home</li>
              <li class="breadcrumb-item active">Settings</li>
            </ol>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->

    <!-- Main content -->
    <div class="content">
      <div class="container-fluid">
        <div class="row">
         
          <!-- /.col-md-3 -->
          <div class="col-lg-3">
            <div class="card">
              <Loader v-if="loadingRates" style="height: 100%" />
               <form @submit.prevent="updateGeneralSettings()" @keydown="form.onKeydown($event)">
              <div class="card-header">
                <h1 class="card-title"><b>General Settings</b></h1>
                <div class="card-tools">
                  <button type="submit" class="btn btn-sm btn-primary" data-toggle="tooltip" title="Save changes">
                    <i class="fa fa-floppy-o"></i>
                  </button>
                </div>
              </div>
              <div class="card-body p-0">
               <table  class="table table-striped">
                <tbody>
                  <tr>
                      <td width="60%">Surcharge (%)</td>
                      <td><input class="form-control" type="text" maxlength="10" v-model="form.surcharge" v-mask-decimal.us="2"></td>
                  </tr>
                  <tr>
                      <td width="60%">Senior Discount (%)</td>
                      <td><input class="form-control" type="text" maxlength="10" v-model="form.senior" v-mask-decimal.us="2"></td>
                  </tr>
                  <tr>
                      <td width="60%">PWD Discount (%)</td>
                      <td><input class="form-control" type="text" maxlength="10" v-model="form.pwd" v-mask-decimal.us="2"></td>
                  </tr>
                </tbody>
              </table>
              </div>
               </form>
            </div>
          </div>
          <!-- /.col-md-3 -->
          <!-- /.col-md-3 -->
          <div class="col-lg-3">
            <div class="card">
              <Loader v-if="loadingRates" style="height: 100%" />
               <form @submit.prevent="updateResidentialTarif()" @keydown="form.onKeydown($event)">
              <div class="card-header">
                <h1 class="card-title"><b>Residential Tarif (1/2" Pipe)</b></h1>
                <div class="card-tools">
                  <button type="submit" class="btn btn-primary btn-sm" data-toggle="tooltip" title="Save changes">
                    <i class="fa fa-floppy-o"></i>
                  </button>
                </div>
              </div>
              <div class="card-body p-0">
               <table class="table table-striped">
                <tbody>
                  <tr>
                      <td width="60%">0-5 cu.m. (Min. Rate)</td>
                      <td><input class="form-control" type="text" maxlength="10" v-model="form.res_bracket1" v-mask-decimal.us="2"></td>
                  </tr>
                  <tr>
                      <td width="60%">6-10 cu.m.</td>
                      <td><input class="form-control" type="text" maxlength="10" v-model="form.res_bracket2" v-mask-decimal.us="2"></td>
                  </tr>
                  <tr>
                      <td width="60%">11-20 cu.m.</td>
                      <td><input class="form-control" type="text" maxlength="10" v-model="form.res_bracket3" v-mask-decimal.us="2"></td>
                  </tr>
                  <tr>
                      <td width="60%">21-30 cu.m.</td>
                      <td><input class="form-control" type="text" maxlength="10" v-model="form.res_bracket4" v-mask-decimal.us="2"></td>
                  </tr>
                  <tr>
                      <td width="60%">31-40 cu.m.</td>
                      <td><input class="form-control" type="text" maxlength="10" v-model="form.res_bracket5" v-mask-decimal.us="2"></td>
                  </tr>
                  <tr>
                      <td width="60%">Over 40 cu.m.</td>
                      <td><input class="form-control" type="text" maxlength="10" v-model="form.res_bracket6" v-mask-decimal.us="2"></td>
                  </tr>
                </tbody>
              </table>
              </div>
               </form>
            </div>
          </div>
          <!-- /.col-md-3 -->
          <!-- /.col-md-3 -->
          <div class="col-lg-3">
            <div class="card">
              <Loader v-if="loadingRates" style="height: 100%" />
               <form @submit.prevent="updateCommercialTarifHalf()" @keydown="form.onKeydown($event)">
              <div class="card-header">
                <h1 class="card-title"><b>Commercial Tarif (1/2" Pipe)</b></h1>
                <div class="card-tools">
                  <button type="submit" class="btn btn-primary btn-sm" data-toggle="tooltip" title="Save changes">
                    <i class="fa fa-floppy-o"></i>
                  </button>
                </div>
              </div>
              <div class="card-body p-0">
               <table class="table table-striped">
                <tbody>
                  <tr>
                      <td width="60%">0-15 cu.m. (Min. Rate)</td>
                      <td><input class="form-control" type="text" maxlength="10" v-model="form.com_bracket1" v-mask-decimal.us="2"></td>
                  </tr>
                  <tr>
                      <td width="60%">16-30 cu.m.</td>
                      <td><input class="form-control" type="text" maxlength="10" v-model="form.com_bracket2" v-mask-decimal.us="2"></td>
                  </tr>
                  <tr>
                      <td width="60%">31-1000 cu.m.</td>
                      <td><input class="form-control" type="text" maxlength="10" v-model="form.com_bracket3" v-mask-decimal.us="2"></td>
                  </tr>
                  <tr>
                      <td width="60%">Over 1000 cu.m.</td>
                      <td><input class="form-control" type="text" maxlength="10" v-model="form.com_bracket4" v-mask-decimal.us="2"></td>
                  </tr>
                </tbody>
              </table>
              </div>
               </form>
            </div>
          </div>
          <!-- /.col-md-3 -->
          <!-- /.col-md-3 -->
          <div class="col-lg-3">
            <div class="card">
              <Loader v-if="loadingRates" style="height: 100%" />
               <form @submit.prevent="updateCommercialTarifTwo()" @keydown="form.onKeydown($event)">
              <div class="card-header">
                <h1 class="card-title"><b>Commercial Tarif (2" Pipe)</b></h1>
                <div class="card-tools">
                  <button type="submit" class="btn btn-primary btn-sm" data-toggle="tooltip" title="Save changes">
                    <i class="fa fa-floppy-o"></i>
                  </button>
                </div>
              </div>
              <div class="card-body p-0">
               <table class="table table-striped">
                <tbody>
                  <tr>
                      <td width="60%">0-15 cu.m. (Min. Rate)</td>
                      <td><input class="form-control" type="text" maxlength="10" v-model="form.com_bracket5" v-mask-decimal.us="2"></td>
                  </tr>
                  <tr>
                      <td width="60%">16-30 cu.m.</td>
                      <td><input class="form-control" type="text" maxlength="10" v-model="form.com_bracket6" v-mask-decimal.us="2"></td>
                  </tr>
                  <tr>
                      <td width="60%">31-1000 cu.m.</td>
                      <td><input class="form-control" type="text" maxlength="10" v-model="form.com_bracket7" v-mask-decimal.us="2"></td>
                  </tr>
                  <tr>
                      <td width="60%">Over 1000 cu.m.</td>
                      <td><input class="form-control" type="text" maxlength="10" v-model="form.com_bracket8" v-mask-decimal.us="2"></td>
                  </tr>
                </tbody>
              </table>
              </div>
               </form>
            </div>
          </div>
          <!-- /.col-md-3 -->
          <!-- /.col-md-9 
          <div class="col-lg-9">
            <div class="card">
              <div class="card-header">
                <h5 class="m-0">General Settings</h5>
              </div>
              <div class="card-body p-0">
               <Loader v-if="loadingSettings" />
               <table v-else class="table responsive-table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Options</th>
                    <th scope="col" class="pl-3">Surcharge (%)</th>
                    <th scope="col">Senior Discount (%)</th>
                    <th scope="col">Late Payment (%)</th>
                    <th scope="col">Effective Start Date</th>
                    <th scope="col">Effective End Date</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">
                      <button type="button" @click="toggleToast" class="btn bg-gradient-warning"><i class="fas fa-edit"></i></button>&nbsp;
                      <button type="button" @click="toggleToast" class="btn bg-gradient-danger"><i class="fas fa-stop"></i></button>
                    </th>
                    <td data-title="Surcharge (%)">20.00</td>
                    <td data-title="Senior Discount (%)">5.00</td>
                    <td data-title="Late Payment (%)">20.00</td>
                    <td data-title="Effective Start Date">01/01/2019</td>
                    <td data-title="Effective End Date">-</td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <button type="button" @click="toggleToast" class="btn bg-gradient-primary"><i class="fas fa-save"></i></button>&nbsp;
                    </th>
                    <td data-title="Surcharge (%)">15.00</td>
                    <td data-title="Senior Discount (%)">4.00</td>
                    <td data-title="Late Payment (%)" >15.00</td>
                    <td data-title="Effective Start Date">01/01/2018</td>
                    <td data-title="Effective End Date">12/31/2018</td>
                  </tr>
                </tbody>
              </table>
              </div>
            </div>

          </div>
           /.col-md-9 -->
        </div>
        <!-- /.row -->
        <div class="row">
          
        </div>
        <!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <!-- /.content -->
  </div>
  <!-- /.content-wrapper -->
</template>

<script>

import {mapGetters} from 'vuex'
import axios from 'axios'
import Loader from '@/components/Loader'
import Form from 'vform'

export default {
  name: 'Settings',

  components: {
    Loader,
  },

  data() {
    return {
      loadingSettings: false,
      loadingRates: false,
      settings : {},
      form: new Form({
        surcharge: '',
        senior: '',
        pwd: '',
        res_bracket1: '',
        res_bracket2: '',
        res_bracket3: '',
        res_bracket4: '',
        res_bracket5: '',
        res_bracket6: '',
        com_bracket1: '',
        com_bracket2: '',
        com_bracket3: '',
        com_bracket4: '',
        com_bracket5: '',
        com_bracket6: '',
        com_bracket7: '',
        com_bracket8: '',
      }),
    }
  },

  computed: {
    ...mapGetters(['user'])
  },

  methods: {

    toggleToast() {
      this.$swal.fire({
        icon: 'info',
        title: 'Setup this view first'
      })
    },

    restrictDecimal() {
      this.form.surcharge=this.form.surcharge.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
    },

    updateGeneralSettings() {
      this.$Progress.start();
      this.form.put('api/settings/general').then(()=>{
        this.$swal.fire({
          icon: 'success',
          title: 'General settings updated',
        })
        this.$Progress.finish();
      }).catch(()=>{
        this.$swal.fire({
          icon: 'error',
          title: 'General settings updating failed'
        });
        this.$Progress.fail();
      });
    },

    updateResidentialTarif() {
      this.$Progress.start();
      this.form.put('api/settings/residential').then(()=>{
        this.$swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Residential tarif updated',
        })
        this.$Progress.finish();
      }).catch(()=>{
        this.$swal.fire({
          icon: 'error',
          title: 'Residential settings updating failed'
        });
        this.$Progress.fail();
      });
    },

    updateCommercialTarifHalf() {
      this.$Progress.start();
      this.form.put('api/settings/commercial').then(()=>{
        this.$swal.fire({
          icon: 'success',
          title: 'Commercial tarif (1/2") updated',
          //timer: 334000,
        })
        this.$Progress.finish();
      }).catch(()=>{
        this.$swal.fire({
          icon: 'error',
          html: 'Commercial tarif (1/2") updating failed'
        });
        this.$Progress.fail();
      });
    },

    updateCommercialTarifTwo() {
      this.$Progress.start();
      this.form.put('api/settings/commercial').then(()=>{
        this.$swal.fire({
          icon: 'success',
          title: 'Commercial tarif (2") updated',
          //timer: 334000,
        })
        this.$Progress.finish();
      }).catch(()=>{
        this.$swal.fire({
          icon: 'error',
          html: 'Commercial tarif (2") updating failed'
        });
        this.$Progress.fail();
      });
    },

    async loadData() {
        this.loadingSettings = true;
        this.loadingRates = true;
        await axios.get('api/settings').then(({ data }) => (this.settings = data));

        this.form.surcharge = this.settings[0].value;
        this.form.senior = this.settings[1].value;
        this.form.pwd = this.settings[2].value;

        this.form.res_bracket1 = this.settings[3].value;
        this.form.res_bracket2 = this.settings[4].value;
        this.form.res_bracket3 = this.settings[5].value;
        this.form.res_bracket4 = this.settings[6].value;
        this.form.res_bracket5 = this.settings[7].value;
        this.form.res_bracket6 = this.settings[8].value;

        this.form.com_bracket1 = this.settings[9].value;
        this.form.com_bracket2 = this.settings[10].value;
        this.form.com_bracket3 = this.settings[11].value;
        this.form.com_bracket4 = this.settings[12].value;

        this.form.com_bracket5 = this.settings[13].value;
        this.form.com_bracket6 = this.settings[14].value;
        this.form.com_bracket7 = this.settings[15].value;
        this.form.com_bracket8 = this.settings[16].value; 

        this.loadingSettings = false;
        this.loadingRates = false;
    }
  },

  mounted() {
    this.$Progress.start();
    this.loadData();
    this.$Progress.finish();
  },

}
</script>


<style scoped>

</style>
