<template>
  <div v-if="user" class="content-wrapper">
    <!-- Modals -->

    <!-- booklet modal -->
    <div class="modal fade" data-backdrop="static" id="avatarmodal" tabindex="-1" role="dialog" aria-labelledby="avatarModalLabel1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-md" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="card-title modal-title"><b>Change Profile Picture</b></h1>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <div class="row" v-show="!cropped">
              <div class="col-12 text-center">
                <vue-avatar
                  :width="260"
                  :height="260"
                  :rotation="rotation"
                  :borderRadius="borderRadius"
                  :scale="scale"
                  ref="vueavatar"
                  @vue-avatar-editor:image-ready="onImageReady"
                  >
                </vue-avatar>
              </div>
            </div>
            <div class="row" v-show="!cropped">
              <div class="col-6 text-center">
                <label>Zoom : {{scale}}x<br><input type="range" min=1 max=3 step=0.02 v-model.number='scale' /></label>
              </div>
              <div class="col-6 text-center">
                <label>Rotation : {{rotation}}°<br><input type="range" min=0 max=360 step=1 v-model.number='rotation' /></label>
              </div>
            </div>
            <div class="row" v-show="!cropped">
              <div class="col-12 text-center">
                <button class="btn btn-primary mr-2" v-on:click="uploadClicked()"><i class="fa fa-upload" aria-hidden="true"></i> Upload Photo</button>
                <button class="btn btn-primary" :disabled="!imageready" v-on:click="cropClicked()"><i class="fa fa-crop" aria-hidden="true"></i> Crop Photo</button>
              </div>
            </div>

            <div class="row" v-show="cropped">
              <div class="col-12 text-center">
                <img class="img-thumbnail mb-3" :src="croppedImage" alt="" style="border-radius: 50%;" />
              </div>
            </div>
            <div class="row" v-show="cropped">
              <div class="col-12 text-center">
                <button v-show="cropped" class="btn btn-primary mr-2" v-on:click="resetClicked()"><i class="fa fa-undo" aria-hidden="true"></i> Reset</button>
              <button v-show="cropped" :disabled="!croppedImage||!cropped||!form_avatar.id" class="btn btn-success" v-on:click="saveClicked()"><i class="fa fa-save" aria-hidden="true"></i> Save</button>
              </div>
            </div>

          </div>

          <div class="card-footer">
            <div class="row col-12 p-0 m-0">
                <div class="col-8 p-0">
                </div>
                <div class="text-right col-12 p-0">
                  <button type="button" class="btn btn-default" data-dismiss="modal">Cancel</button>&nbsp;
                </div>
            </div>
          </div>

        </div>
      </div>
    </div> 

    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>My Profile</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">Home</li>
              <li class="breadcrumb-item active">My Profile</li>
            </ol>
          </div>
        </div>
      </div><!-- /.container-fluid -->
    </section>

    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-3">

            <!-- Profile Image -->
            <div class="card card-primary card-outline">
              <Loader v-if="loading" style="height: 100%" />
              <div class="card-body box-profile">
                <div>
                  
                  <div class="text-center image">
                    <img v-if="avatar" width="250px" height="250px" class="img-fluid img-circle elevation-2" :src="this.$profile_picture_baseurl + avatar" alt="User profile picture">
                    <div class="rank-label-container">
                        <span @click="showAvatarModal" class="label label-default rank-label"><i class="fa fa-camera" aria-hidden="true"></i></span>
                    </div>
                  </div>

                  <skeleton-loader-vue v-if="loading"  class="skeleton mt-5 mb-2" type="rect" :rounded="true" :width="50" :height="25" animation="fade" />
                  <h3 v-else class="profile-username text-center mt-5">{{form.name}}</h3>
                  <skeleton-loader-vue v-if="loading" class="skeleton mb-4" type="rect" :rounded="true" :width="50" :height="16" animation="fade" />
                  <p v-else class="text-muted text-center">{{form.role}}</p>
                  
                  
                  <ul class="list-group list-group-unbordered mb-3">
                    <li class="list-group-item">
                      <b>Email</b> <a class="float-right">{{form.email}}</a>
                    </li>
                    <li class="list-group-item">
                      <b>Position</b> <a class="float-right">{{form.position}}</a>
                    </li>
                    <li class="list-group-item">
                      <b>Access Role</b> <a class="float-right">{{form.role}}</a>
                    </li>
                  </ul>
                  <!--
                  <a href="#" class="btn btn-primary btn-block"><b>Message</b></a>
                  -->
                </div>
              </div>
              <!-- /.card-body -->
            </div>
            <!-- /.card -->

          </div>
          <!-- /.col -->
          <div class="col-md-9">
            <div class="card">
              <Loader v-if="loading" style="height: 100%" />
              <div class="card-header p-2">
                <ul class="nav nav-pills">
                  
                  <li class="nav-item"><a class="nav-link active" href="#settings" data-toggle="tab">Settings</a></li>
                  <!--<li class="nav-item"><a class="nav-link active" href="#timeline" data-toggle="tab">Timeline</a></li>-->
                </ul>
              </div><!-- /.card-header -->
              <div class="card-body">
                <div class="tab-content">

                  <div class="active tab-pane" id="settings">
                    <form class="form-horizontal" @submit.prevent="updateProfile" @keydown="form.onKeydown($event)">
                      
                      <div class="form-group row">
                        <label for="inputEmail" class="col-sm-2 col-form-label">Email<span class="text-danger"></span></label>
                        <div class="col-sm-10">
                          <input type="email" class="form-control" id="inputEmail" placeholder="Email" v-model="form.email" readonly> 
                        </div>
                      </div>
                      <div class="form-group row">
                        <label for="inputRole" class="col-sm-2 col-form-label">Access Role<span class="text-danger"></span></label>
                        <div class="col-sm-10">
                          <input type="text" class="form-control" id="inputRole" placeholder="Role" v-model="form.role" readonly>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label for="inputName" class="col-sm-2 col-form-label">Name<span class="text-danger">*</span></label>
                        <div class="col-sm-10">
                          <input type="text" class="form-control" id="inputName" placeholder="Name" v-model="form.name">
                        </div>
                      </div>
                      <div class="form-group row">
                        <label for="inputPosition" class="col-sm-2 col-form-label">Position<span class="text-danger">*</span></label>
                        <div class="col-sm-10">
                          <input type="text" class="form-control" id="inputPosition" placeholder="Position" v-model="form.position">
                        </div>
                      </div>
                      <div class="form-group row">
                        <label for="inputPassword" class="col-sm-2 col-form-label">Change Password<span class="text-danger">*</span></label>
                        <div class="col-sm-10">
                          <input type="password" class="form-control" id="inputPassword" placeholder="Password" v-model="form.password">
                        </div>
                      </div>
                      <div class="form-group row">
                        <label for="inputConfirmPassword" class="col-sm-2 col-form-label">Confirm Password<span class="text-danger">*</span></label>
                        <div class="col-sm-10">
                          <input type="password" class="form-control" id="inputConfirmPassword" placeholder="Confirm Password" v-model="form.password_confirm">
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="offset-sm-2 col-sm-10">
                          <div class="checkbox">
                            <label>
                              <input type="checkbox" id="checkboxPrimary1" v-model="confirm"> Confirm changes
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="offset-sm-2 col-sm-10 text-right">
                          <button type="submit" :disabled="!confirm || form.password != form.password_confirm" class="btn btn-danger">Save</button>
                        </div>
                      </div>
                    </form>
                  </div>
                  <!-- /.tab-pane -->
                </div>
                <!-- /.tab-content -->
              </div><!-- /.card-body -->
            </div>
            <!-- /.nav-tabs-custom -->
          </div>
          <!-- /.col -->
        </div>
        <!-- /.row -->
      </div><!-- /.container-fluid -->
    </section>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import axios from 'axios'
import Loader from '@/components/Loader'
import UploadImage from '@/components/UploadImage'
import Form from 'vform'
import {VueAvatar} from 'vue-avatar-editor-improved'
import VueAvatarScale from '@/components/VueAvatarScale'
import VueSkeletonLoader from 'skeleton-loader-vue';

export default {
  name: 'Profile',

  components: {
    Loader, UploadImage, VueAvatar, VueAvatarScale, VueSkeletonLoader,
  },

  props:{
      callMethod: '',
  },

  data() {
    return {
      rotation: 0,
      scale: 1,
      borderRadius: 200,
      cropped: false,
      imageready: false,
      croppedImage: null,
      avatar: null,
      loading: false,
      skeleton: true,
      confirm: false,
      profile : {},
      form: new Form({
        id: '',
        name: '',
        position: '',
        role: '',
        password: '',
        password_confirm: '',
        photo: '',
        email: '',
      }),
      form_avatar: new Form({
        id: null,
        photo: null,
      }),
    }
  },

  methods: {
    uploadClicked(){
      document.getElementById('avatarEditorCanvas').click();
    },

    saveClicked: function saveClicked() {
      var img = this.$refs.vueavatar.getImageScaled();
      this.$refs.image.src = img.toDataURL();
    },

    cropClicked(){
      var img = this.$refs.vueavatar.getImageScaled()
      this.croppedImage = img.toDataURL()
      this.form_avatar.id = this.user.id
      this.form_avatar.photo = img.toDataURL()
      this.cropped = true
    },

    setScale:function(scale){
      this.scale = parseFloat(scale)
    },
        
    onImageReady: function onImageReady() {
        this.scale = parseFloat(1);
        this.rotation = parseFloat(0);
        this.imageready = true;
    },

    saveClicked(){
        this.$Progress.start();
        this.form_avatar.put('api/users/picture/' + this.user.id).then(()=>{
                this.$swal.fire({
                icon: 'success',
                title: 'Profile picture updated'
            })
            this.$Progress.finish();
            this.refreshAvatar();
            this.closeAvatarModal();
            this.cropped = false;
        }).catch(()=>{
                this.$swal.fire({
                icon: 'error',
                title: 'Picture updating failed'
            });
            this.$Progress.fail();
        });
    },

    resetClicked(){
      this.cropped = false
    },

    showAvatarModal() {
      $('#avatarmodal').modal('show');
    },

    closeAvatarModal() {
      this.refreshAvatar();
      this.form_avatar.reset();
      $('#avatarmodal').modal('hide');
    },

    updateProfile() {
      this.$Progress.start();
      this.form.put('api/users/profile/' + this.form.id).then(()=>{
        this.$swal.fire({
          icon: 'success',
          title: 'Profile updated'
        })
        this.form.password = "";
        this.form.password_confirm = "";
        this.$Progress.finish();
      }).catch(()=>{
        this.$swal.fire({
          icon: 'error',
          title: 'Profile update failed'
        });
        this.$Progress.fail();
      });
    },

    async refreshAvatar() {
      this.loading = true;
      await axios.get("api/users/" + this.user.id ).then(({ data }) => (
        this.form.fill(data),
        localStorage.setItem('avatar', data.photo)
      ));
      this.avatar = localStorage.getItem("avatar")
      this.callMethod()
      this.loading = false;
    },

    async loadData() {
      this.loading = true;
      await axios.get("api/users/" + this.user.id ).then(({ data }) => (
        this.form.fill(data)
      ));
      this.loading = false;
      
    }
  },

  computed: {
    ...mapGetters(['user']),
  },

  mounted() {
    this.$Progress.start();
    this.loadData();
    this.avatar = localStorage.getItem("avatar")
    this.$Progress.finish();
    this.skeleton = false;
  },

}
</script>

<style scoped>
.rank-label-container {
    margin-top: -35px;
    /* z-index: 1000; */
    text-align: center;
}

.label.label-default.rank-label:hover{
   background-color:#9A9A9A;
   cursor: pointer;
}

.label.label-default.rank-label {
    background-color: #747474;
    margin-left: 150px;
    border-radius: 50%;
    padding: 12px;
}
.skeleton {
  width: 100% !important;
}
</style>
